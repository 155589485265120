import React, { useState, useReducer, useContext, useEffect } from 'react';
import './App.css';
// import { GlobalState } from './App.js';
import { reducer } from './App.js';
import iconSignature from './signature.png';
import iconRevocation from './revocation.png';
import iconSwitchCertificant from './switchCertificant.png';
import iconMonaToMark from './starfish.png';
import iconUnderConstruction from './underConstruction.png';

const words = require('./words.json');

let GlobalState;

function Help() {
  const urlParams = (new URL(document.location)).searchParams;
  const initialState = {
    helpContent: 'introduction',
    language: urlParams.get('language') === null ? 'english' : urlParams.get('language')
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  GlobalState = React.createContext([state, dispatch]);

  useEffect( () => {
    if (window.mpurse !== undefined) {
      checkTokensAndSet(state, dispatch);
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [state.helpContent])

  return (
    <div className='fullScreenHelp wordWrapSpBreakWord'>
      <div className='navigation displaySpNone'> {/* navigation */}
        <div>
          <button className='button0 focusEffect01 textLeft' tabindex='0' onClick={ () => {
            dispatch({type: 'setState', key: 'helpContent', value: 'introduction'});
          }}>
            monatrustとは？
          </button>
        </div>
        <div>
          <button className='button0 focusEffect01 textLeft' tabindex='0' onClick={ () => {
            dispatch({type: 'setState', key: 'helpContent', value: 'searchCertificate'});
          }}>
            証明書を検索する
          </button>
        </div>
        <div>
          <button className='button0 focusEffect01 textLeft' tabindex='0' onClick={ () => {
            dispatch({type: 'setState', key: 'helpContent', value: 'insertCertificate'});
          }}>
            自分の証明書をつくる
          </button>
        </div>
        <div>
          <button className='button0 focusEffect01 textLeft' tabindex='0' onClick={ () => {
            dispatch({type: 'setState', key: 'helpContent', value: 'sign'});
          }}>
            他人の証明書に署名する
          </button>
        </div>
        <div>
          <button className='button0 focusEffect01 textLeft' tabindex='0' onClick={ () => {
            dispatch({type: 'setState', key: 'helpContent', value: 'revoke'});
          }}>
            署名を無効にする
          </button>
        </div>
        <div>
          <button className='button0 focusEffect01 textLeft' tabindex='0' onClick={ () => {
            dispatch({type: 'setState', key: 'helpContent', value: 'signMessage'});
          }}>
            メッセージに署名する
          </button>
        </div>
        <div>
          <button className='button0 focusEffect01 textLeft' tabindex='0' onClick={ () => {
            dispatch({type: 'setState', key: 'helpContent', value: 'verify'});
          }}>
            署名を検証する
          </button>
        </div>
        <div>
          <button className='button0 focusEffect01 textLeft' tabindex='0' onClick={ () => {
            dispatch({type: 'setState', key: 'helpContent', value: 'mark'});
          }}>
            証明書、署名にマークを付ける
          </button>
        </div>
        <div>
          <button className='button0 focusEffect01 textLeft' tabindex='0' onClick={ () => {
            dispatch({type: 'setState', key: 'helpContent', value: 'specificationOfSigning'});
          }}>
            署名方式
          </button>
        </div>
        <div>
          <button className='button0 focusEffect01 textLeft' tabindex='0' onClick={ () => {
            dispatch({type: 'setState', key: 'helpContent', value: 'specificationOfUrlParameters'});
          }}>
            URLパラメータ
          </button>
        </div>
        <div>
          <button className='button0 focusEffect01 textLeft' tabindex='0' onClick={ () => {
            dispatch({type: 'setState', key: 'helpContent', value: 'specificationOfApi'});
          }}>
            API
          </button>
        </div>
      </div>
      <div className='helpText widthSp100'>
        <Routing />
      </div>
    </div>
  );
}

/*              <div className='margin01 font1p5'>︙</div> */

// HELP MENU
function HelpMenu() {
  const [state, dispatch] = useContext(GlobalState);
  return (
        <div className='mainMenu'>
          <div className='flexRow justifyEnd'>
            <button className='button0 focusEffect01' tabindex="0" onClick={ () => document.getElementById("PUCB_helpMenu").checked=true } >
              ︙
            </button>
          </div>
          <input className='popUpCheckBox' id='PUCB_helpMenu' type='checkbox' />
          <div className='popUpRight'>
            <label for='PUCB_helpMenu' className='closePopUp' />
            <div className='popUpMenu font1Sp1p5 border'>
              <div className='width100 flexRow justifyEnd'>
                <button className='button0 focusEffect01' tabindex="0" onClick={ () => document.getElementById("PUCB_helpMenu").checked=false } >
                  x
                </button>
              </div>
              <div>
                <button className='button0 focusEffect01 leftText' tabindex="0" onClick={ () => {
                  if (state.helpContent === 'introduction') {
                    document.getElementById("PUCB_helpMenu").checked=false;
                  }
                  else {
                    dispatch({type: 'setState', key: 'helpContent', value: 'introduction'});
                  }
                }}>
                  monatrustとは？
                </button>
              </div>
              <div>
                <button className='button0 focusEffect01 leftText' tabindex="0" onClick={ () => {
                  if (state.helpContent === 'searchCertificate') {
                    document.getElementById("PUCB_helpMenu").checked=false;
                  }
                  else {
                    dispatch({type: 'setState', key: 'helpContent', value: 'searchCertificate'});
                  }
                }}>
                  証明書を検索する
                </button>
              </div>
              <div>
                <button className='button0 focusEffect01 leftText' tabindex='0' onClick={ () => {
                  if (state.helpContent === 'insertCertificate') {
                    document.getElementById("PUCB_helpMenu").checked=false;
                  }
                  else {
                    dispatch({type: 'setState', key: 'helpContent', value: 'insertCertificate'});
                  }
                }}>
                  自分の証明書をつくる
                </button>
              </div>
              <div>
                <button className='button0 focusEffect01 leftText' tabindex='0' onClick={ () => {

                  if (state.helpContent === 'sign') {
                    document.getElementById("PUCB_helpMenu").checked=false;
                  }
                  else {
                    dispatch({type: 'setState', key: 'helpContent', value: 'sign'});
                  }
                }}>
                  他人の証明書に署名する
                </button>
              </div>
              <div>
                <button className='button0 focusEffect01 leftText' tabindex='0' onClick={ () => {
                  if (state.helpContent === 'revoke') {
                    document.getElementById("PUCB_helpMenu").checked=false;
                  }
                  else {
                    dispatch({type: 'setState', key: 'helpContent', value: 'revoke'});
                  }
                }}>
                  署名を無効にする
                </button>
              </div>
              <div>
                <button className='button0 focusEffect01 leftText' tabindex='0' onClick={ () => {
                  if (state.helpContent === 'signMessage') {
                    document.getElementById("PUCB_helpMenu").checked=false;
                  }
                  else {
                    dispatch({type: 'setState', key: 'helpContent', value: 'signMessage'});
                  }
                }}>
                  メッセージに署名する
                </button>
              </div>
              <div>
                <button className='button0 focusEffect01 leftText' tabindex='0' onClick={ () => {
                  if (state.helpContent === 'verify') {
                    document.getElementById("PUCB_helpMenu").checked=false;
                  }
                  else {
                    dispatch({type: 'setState', key: 'helpContent', value: 'verify'});
                  }
                }}>
                  署名を検証する
                </button>
              </div>
              <div>
                <button className='button0 focusEffect01 leftText' tabindex='0' onClick={ () => {
                  if (state.helpContent === 'mark') {
                    document.getElementById("PUCB_helpMenu").checked=false;
                  }
                  else {
                    dispatch({type: 'setState', key: 'helpContent', value: 'mark'});
                  }
                }}>
                  証明書、署名にマークを付ける
                </button>
              </div>
              <div>
                <button className='button0 focusEffect01 leftText' tabindex='0' onClick={ () => {
                  if (state.helpContent === 'specificationOfSigning') {
                    document.getElementById("PUCB_helpMenu").checked=false;
                  }
                  else {
                    dispatch({type: 'setState', key: 'helpContent', value: 'specificationOfSigning'});
                  }
                }}>
                  署名方式
                </button>
              </div>
              <div>
                <button className='button0 focusEffect01 leftText' tabindex='0' onClick={ () => {
                  if (state.helpContent === 'specificationOfUrlParameters') {
                    document.getElementById("PUCB_helpMenu").checked=false;
                  }
                  else {
                    dispatch({type: 'setState', key: 'helpContent', value: 'specificationOfUrlParameters'});
                  }
                }}>
                  URLパラメータ
                </button>
              </div>
              <div>
                <button className='button0 focusEffect01 leftText' tabindex='0' onClick={ () => {
                  if (state.helpContent === 'specificationOfApi') {
                    document.getElementById("PUCB_helpMenu").checked=false;
                  }
                  else {
                    dispatch({type: 'setState', key: 'helpContent', value: 'specificationOfApi'});
                  }
                }}>
                  API
                </button>
              </div>
            </div>
          </div>
        </div>
  );
}
function Routing() {
  const [state, dispatch] = useContext(GlobalState);

  switch (state.helpContent) {
    case 'introduction':
      return (
        <Introduction />
      );
      break;
    case 'searchCertificate':
      return (
        <SearchCertificate />
      );
      break;
    case 'insertCertificate':
      return (
        <InsertCertificate />
      );
      break;
    case 'sign':
      return (
        <Sign />
      );
      break;
    case 'revoke':
      return (
        <Revoke />
      );
      break;
    case 'signMessage':
      return (
        <SignMessage />
      );
      break;
    case 'verify':
      return (
        <Verify />
      );
      break;
    case 'mark':
      return (
        <Mark />
      );
      break;
    case 'specificationOfSigning':
      return (
        <SpecificationOfSigning />
      );
      break;
    case 'specificationOfUrlParameters':
      return (
        <SpecificationOfUrlParameters />
      );
      break;
    case 'specificationOfApi':
      return (
        <SpecificationOfApi />
      );
      break;
  }
}

function HeaderHelp() {
  return (
    <div className='headerHelp'>
      <HelpMenu />
    </div>
  )
}

function Introduction() {
  return (
    <div className=''>
      <HeaderHelp />
      <div className='headerHelpPaddng' />
      <div className='textLeft fontSize1p5 marginHelp'>
        monatrustとは？
      </div>
      <div className='textLeft marginHelp'>
        monatrustはモナコインのアドレス証明書データベースです。モナコインアドレスと何かのサービス（コミュニティ）のIDを紐づけて登録します。
        自分で登録しただけでは何の信頼性もありませんが、ユーザがお互いに署名しあうことによりその信頼性を高めます。（Web Of Trust - 信頼の輪）<br/>
        信頼性のあるアドレスは送金用途に利用することはもちろん、メッセージの署名の検証（本当にその人が書いたメッセージかどうか確かめること）にも利用できます。
        また、発展的な用途としては、そのアドレスに紐づく様々な情報でそのアドレス（その人）の信頼性を推し測ることもできます。<br/>
        デジタル署名のこと、Web Of Trustのこと、monatrustのことについての
        <a href='https://docs.google.com/presentation/d/1k1nA0xvTO4lVoh4jJuqaIz4tFE3E3CyQFvSeN2LvHLU/edit#slide=id.p'>資料</a>がありますので、
        読んでもらえると幸いです。<br/><br/>
        

        monatrustはMpurseに最適化してつくられています。Mpurse無しで利用することもできますが、ブラウザの拡張機能としてインストールすることをお勧めします。
        （ブラウザはchromeを推奨します。）Mpurseのインストール方法、使い方はググればいろいろ出てくると思います。<br/><br/>

        また基本的に、証明書の検証など、当WEBサービスに依存せずに行えるコンセプトでつくられています。monatrustで提供される情報に関して、
        開発・運営者は一切責任を負いかねます。
      </div>
    </div>
  );
}

function SearchCertificate() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div className=''>
      <HeaderHelp />
      <div className='headerHelpPaddng' />
      <div className='textLeft fontSize1p5 marginHelp'>
        証明書を検索する
      </div>
      <div className='textLeft marginHelp'>
        メニューから"{words.searchMode[state.language]}"をクリックし、"{words.searchPattern[state.language]}"から検索方法を選んでください。<br/>
        検索方法は以下のとおりです。<br/>
        <br/>
        <table className='marginHelp'>
          <tr>
            <td className='textCenter'> {words.all[state.language]} </td><td> 全件検索 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.number[state.language]} </td><td> 証明書番号で検索 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.id[state.language]} </td><td> IDで検索 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.community[state.language]} </td><td> コミュニティで検索 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.community_id[state.language]} </td><td> コミュニティとIDで検索 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.certificantAddress[state.language]} </td><td> 被証明者のアドレスで検索 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.certifierAddress[state.language]} </td><td> 証明者のアドレスで検索 </td>
          </tr>
        </table>
        <br/>
        "{words.search[state.language]}"ボタンをクリックすると、青い四角い箱が出てきたと思います。これが証明書の被証明情報（本当はこれに署名を含めて証明書なのですが、
        被証明情報のみでも証明書と呼ぶことにします。）です。白い四角の中の番号が証明書番号、その右側が上から、被証明者のアドレス、コミュニティ、ID、
        証明書作成時刻です。<br/><br/>

        右下の四角をクリックしてください。すると、緑の四角い箱が出てきたと思います。これが署名情報です。上の文字列が証明者のアドレスです。
        緑の箱がひとつであれば、おそらくそれは自己署名です。二つ以上であれば、他人（アドレス違いの同一人物かもしれませんが）から署名されている
        ということになります。<br/><br/>

        緑の箱の<img className='oneFiveEm' src={iconSignature} />のアイコンをクリックしてください。一番上の長い文字列が署名です。
        その下に、ブラウザ等で署名された時刻、サーバに登録された時刻が表示されています。<br/><br/>

        ところでこの署名はだれが作成したのでしょうか。<img className='oneFiveEm' src={iconSwitchCertificant} />のアイコンをクリックしてください。
        ブラウザの新しいタブが開き、青い箱が一つ、もしくは複数表示されたかと思います。
        これがさきほどの署名を作成した人の情報です。（稀に青い箱が一つも表示されないことがあります。それは、署名者が一つも証明書を持っていないということです。）
        ただし、この情報をそのまま鵜呑みにしてはいけません。先程と同様に、青い箱の右下をクリックして、署名者を調べ自分が信じられるアドレスによって
        署名されているか確かめる必要があります。<br/><br/>

        画面のヘッダ部分に"{words.revoked[state.language]}"という文字があるかと思います。これをクリックすると、もしかするとグレーの四角い箱が表示されるかもしれません。
        これは、無効化された署名、もしくは、無効化された証明書（有効な署名が一つも無い証明書）です。<br/>
        グレーの箱の<img className='oneFiveEm' src={iconRevocation} />アイコンをクリックすると、無効化署名および署名時刻が表示されます。
      </div>
    </div>
  );
}

function InsertCertificate() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div className=''>
      <HeaderHelp />
      <div className='headerHelpPaddng' />
      <div className='textLeft fontSize1p5 marginHelp'>
        自分の証明書を作成する
      </div>
      <div className='textLeft marginHelp'>
        メニューから"{words.insertMode[state.language]}"をクリックしてください。以下の項目を入力してください。<br/>
        <br/>
        <table className='marginHelp'>
          <tr>
            <td className='textCenter'> {words.address[state.language]} </td><td> 自分のアドレス。ボタンを押すとMpurseから取得できます。 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.community[state.language]} </td><td> twitter,instagramなどユニークなIDを持つもの。<br/>自由入力ですが大文字小文字等統一してもらえると、検索しやすくなります。 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.id[state.language]} </td><td> 上記コミュニティでのID。(※) </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.time[state.language]} </td><td> 署名する時刻。ボタンを押すと現在時刻が入力されます。エポックミリ秒です。 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.signature[state.language]} </td><td> ボタンを押すと上記入力情報に対しMpurseで署名が作成されます。 </td>
          </tr>
        </table>
        <br/>
        "{words.sign[state.language]}"ボタンを押すと、証明書とそれに対する自己署名が登録されます。<br/>
        これだけだと、ただのオレオレ証明書です。誰かに頼んでこの証明書に署名してもらいましょう。<br/><br/>
        作成した証明書を削除することは原則できません。証明書が不要になった場合は、自己署名を無効化してください。<br/><br/>
        ※ twitterのIDを登録する際は、「@」無しで登録することをお勧めします。その方が、他の人やプログラムから検索されやすくなると思われます。
      </div>
    </div>
  );
}

function Sign() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div className=''>
      <HeaderHelp />
      <div className='headerHelpPaddng' />
      <div className='textLeft fontSize1p5 marginHelp'>
        他人の証明書に署名する
      </div>
      <div className='textLeft marginHelp'>
        <span className='fontRed'>
          これから行うことは、"モナコインアドレス"と"コミュニティおよびそこにおけるID"の紐付きをあなたの名において（あなたのアドレスにおいて）保証する行為です。
          署名しようとする情報（被証明者のアドレス、コミュニティ、ID）が正しいものであることをもう一度確認してください。
          情報の受け渡しは対面がベストですが、現実的にそうもいかないことも多いので、十分に信頼できる経路での受け渡しを行ってください。
        </span><br/><br/>
        メニューから"{words.searchMode[state.language]}"をクリックし、目的の証明書を検索してください。<br/>
        証明書が見つかったら、青い箱の右下をクリックしてください。<br/>
        画面が切り替わったら、以下の項目を入力してください。<br/>
        <br/>
        <table className='marginHelp'>
          <tr>
            <td className='textCenter'> {words.address[state.language]} </td><td> 自分のアドレス。ボタンを押すとMpurseから取得できます。 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.time[state.language]} </td><td> 署名する時刻。ボタンを押すと現在時刻が入力されます。エポックミリ秒です。 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.signature[state.language]} </td><td> ボタンを押すと被証明情報に対しMpurseで署名が作成されます。 </td>
          </tr>
        </table>
        <br/>
        "{words.sign[state.language]}"ボタンを押すと、署名情報が登録されます。<br/><br/>
        登録した署名を削除することは原則できません。署名した内容に誤りを見つけた場合などは、署名を無効化してください。
      </div>
    </div>
  );
}

function Revoke() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div className=''>
      <HeaderHelp />
      <div className='headerHelpPaddng' />
      <div className='textLeft fontSize1p5 marginHelp'>
        署名を無効にする
      </div>
      <div className='textLeft marginHelp'>
        署名を無効にするには、無効化署名を行う必要があります。<br/>
        メニューから"{words.searchMode[state.language]}"をクリックし、無効化したい署名の対象となっている被証明情報を検索してください。<br/>
        対象が見つかったら青い箱の右下をクリックし、表示された署名情報の中から、無効化したいものを探してください。<br/>
        目的の署名情報が見つかったら緑の箱の右下をクリックし、画面が切り替わったら以下の項目を入力してください。<br/>
        <br/>
        <table className='marginHelp'>
          <tr>
            <td className='textCenter'> {words.time[state.language]} </td><td> 無効化署名を行う時刻。ボタンを押すと現在時刻が入力されます。エポックミリ秒です。 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.signature[state.language]} </td><td> ボタンを押すと被証明情報に対しMpurseで無効化署名が作成されます。 </td>
          </tr>
        </table>
        <br/>
        "{words.sign[state.language]}"ボタンを押すと、無効化署名が登録されます。<br/><br/>
      </div>
      <div className='topic'>
        ■onchain revoke<br/>
        <br/>
        署名を行う際、時刻も署名対象に含まれますが、この時刻は署名者が自由に決めることができます。ところで、無効化署名には過去に遡って行いたいという
        インセンティブがはたらくことがあります。署名者が自由に決められる時刻では、本当に署名した時刻としての信頼性に欠けます。そこで、確実にこの時刻以前に
        署名したということを証明するために、無効化署名をブロックチェーンに刻む機能がこのonchain revokeです。<br/>
        実際には、証明書の内容(署名対象)、署名、検証用アドレスをmonapartyのbroadcastを使ってブロックチェーンに書き込んでいます。<br/><br/>
        【やりかた】<br/>
        <ol type='1'>
          <li>このページの上部の手順で普通に無効化署名を行います。</li>
          <li>"{words.time[state.language]}", "{words.signature[state.language]}"の値はそのままで"{words.onchain[state.language]}"ボタンをクリックします。Mpurseが開きますので、broadcastトランザクションを送信してください。成功すると、"{words.txid[state.language]}"フィールドにトランザクションIDが表示されます。</li>
          <li>送信したトランザクションがブロックチェーンに取り込まれるまで、しばし待ちます。（モナコインのブロック生成間隔は平均90秒です。）</li>
          <li>トランザクションがブロックチェーンに取り込まれたら、"{words.setTxid[state.language]}"ボタンをクリックし、monatrustのデータベースに登録します。（ブロックチェーンへの取り込まれ状況は、各種エクスプローラで確認してもいいですが、ときどき"{words.setTxid[state.language]}"ボタンをクリックして確認すればよいと思います。）</li>
          <li>無効化した署名の<img className='oneFiveEm' src={iconRevocation} />をクリックして、"{words.onchainRevokeTXID[state.language]}"の所にトランザクションIDが表示されていれば成功です。</li>
        </ol>
        ※注意事項<br/>
        <ul className='disc'>
          <li>broadcastトランザクションの送信を行うので、少量のMONAが必要です。</li>
          <li>一度もブロックチェーンに現れていないアドレスではbroadcastトランザクションの生成すらできません。上記のとおり、トランザクションの送信にはMONAが必要なので、事前に対象のアドレスにMONAの送信をしておいてください。</li>
          <li>monapartyサーバにて、broadcastトランザクションのtimestampの順序性チェックを行っているようです。タイミングが悪いと、このチェックでエラーが発生します。この場合、"{words.time[state.language]}"を再取得して、再度"mpurse"ボタンで署名を行ってから"{words.onchain[state.language]}"ボタンをクリックし、broadcastトランザクションを送信してください。必然的に、monatrustデータベースとブロックチェーン上の"{words.time[state.language]}", "{words.signature[state.language]}"に差異が発生しますが、問題ありません。</li>
        </ul>
        Mpurseを使わずに、他の方法でbroadcastトランザクションを送信し、そのTXIDをmonatrustに登録することも可能です。その場合、broadcastのtextは以下の形式としてください。<br/><br/>
        <div className='wordBreakBreakAll simpleBox'>
          {'@monatrust {"type":"revoke","version":"1","body":{"certificantAddress":"'}<span className='italic'>string</span>{'","community":"'}<span className='italic'>string</span>{'","id":"'}<span className='italic'>string</span>{'","revocationTimeClient":'}<span className='italic'>number</span>{',"certifierAddress":"'}<span className='italic'>string</span>{'","signatureOfRevocation":"'}<span className='italic'>string</span>{'"}}'}
        </div>
      </div>
    </div>
  );
}

function SignMessage() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div className=''>
      <HeaderHelp />
      <div className='headerHelpPaddng' />
      <div className='textLeft fontSize1p5 marginHelp'>
        メッセージに署名する
      </div>
      <div className='textLeft marginHelp'>
        メニューから"{words.signMessageMode[state.language]}"をクリックしてください。<br/>
        メッセージエリアに署名したいメッセージを入力し、"{words.sign[state.language]}"ボタンを押してください。<br/>
        Mpurseによって署名が作成され、署名エリアに表示されます。<br/><br/>
        "{words.copy[state.language]}"ボタンを押すと、メッセージ、署名に使った秘密鍵に対応するアドレス、署名の3点セットがクリップボードにコピーされます。（monatrust署名検証3点セット形式）<br/>
      </div>
      <div className='topic'>
        ■monatrust署名検証3点セット形式<br/>
        <br/>
        メッセージ、アドレス、署名をまとめた以下のような形式です。<br/>
        <br/>
        --message begin--\n<br/>
        wakiyama\n<br/>
        --message end--\n<br/>
        --address--\n<br/>
        MJehBJHrp8W46XFNpr2QZiy9vRqMVKNpPq\n<br/>
        --signature--\n<br/>
        ILTu3qjES017dt8oZs3khS21oEJGLBf2+HDX8e9Ht4ThDZxz6+f3L1XcaG2J9GcbJLCji7aQ8TJ2WIAnYEy2rqQ=<br/>
      </div>
    </div>
  );
}

function Verify() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div className=''>
      <HeaderHelp />
      <div className='headerHelpPaddng' />
      <div className='textLeft fontSize1p5 marginHelp'>
        署名を検証する
      </div>
      <div className='textLeft marginHelp'>
        この機能は、当WEBサービスをtrustしたうえで利用する機能です。"don't trust"の精神の持ち主であれば、是非、自身の納得のいく方法で検証してください。<br/>
        メニューから"{words.verifySignatureMode[state.language]}"をクリックしてください。<br/>
        以下の項目を入力してください。<br/>
        もし、クリップボードにmonatrust署名検証3点セット形式でデータがコピーされていれば、"{words.paste[state.language]}"ボタンを押して各項目を埋めることができます。
        （Firefoxでは使えない機能です。）<br/>
        <br/>
        <table className='marginHelp'>
          <tr>
            <td className='textCenter'> {words.message[state.language]} </td><td> 検証するメッセージです。 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.address[state.language]} </td><td> 検証するアドレスです。 </td>
          </tr>
          <tr>
            <td className='textCenter'> {words.signature[state.language]} </td><td> 検証する署名です。 </td>
          </tr>
        </table>
        <br/>
        "{words.verify[state.language]}"ボタンを押してみてください。検証に成功すると、"{words.verified[state.language]}"と表示されます。メッセージは入力したアドレスに対応する秘密鍵で署名されている
        （メッセージはアドレスの持ち主が書いた）ということです。<br/>
        検証に失敗すると、"{words.notVerified[state.language]}"と表示されます。いろいろ理由は考えられますが、何かが間違っている、つまり、メッセージはアドレスの持ち主が書いたと
        信じることはできないということです。<br/>
      </div>
    </div>
  );
}

function Mark() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div className=''>
      <HeaderHelp />
      <div className='headerHelpPaddng' />
      <div className='textLeft fontSize1p5 marginHelp'>
        証明書、署名にマークする
      </div>
      <div className='textLeft marginHelp'>
        メニューから"{words.settingMode[state.language]}"をクリックしてください。<br/>
        便利機能として、証明書、署名のアドレスにマーキングする機能が3種類あります。<br/>
        <div className='textLeft marginHelp'>
          <div>
            ■署名ツリー
            <div className='textLeft marginHelp'>
              "{words.markAsTreeFromYourAddress[state.language]}"の隣の"{words.set[state.language]}"をクリックしてください。<br/>
              自分のアドレスを起点に、自分が署名したアドレス、そのアドレスが署名しアドレス、・・・をツリー状に伸ばし、そのツリーに含まれているアドレスに
              マーキングします。起点となる自分のアドレスは
                <span className='relative'>
                  <img className='monaEm' src={iconMonaToMark} />
                  <span className='treeNumber'>0</span>
                </span>
              、1ステップ伸ばした先のアドレスは
                <span className='relative'>
                  <img className='monaEm' src={iconMonaToMark} />
                  <span className='treeNumber'>1</span>
                </span>
              と表示されます。ツリーのステップ数は画面に表示されていますが、
              変更される場合があります。<br/>
              "{words.clear[state.language]}"をクリックするとマークは消えます。
            </div>
          </div>
        </div>
        <div className='textLeft marginHelp'>
          <div>
            ■任意のアドレス
            <div className='textLeft marginHelp'>
              "{words.addressToMark[state.language]}"のテキストエリアにマークしたいアドレスを入力してください。（複数のアドレスを入力する場合は改行を入れてください。）
              "{words.set[state.language]}"ボタンを押すと入力したアドレスに<img className='oneFiveEm' src={iconMonaToMark} />が付きます。<br/>
              "{words.clear[state.language]}"をクリックするとマークは消えます。
            </div>
          </div>
        </div>
        <div className='textLeft marginHelp'>
          <div>
            ■モナパトークンのオーナーのアドレス
            <div className='textLeft marginHelp'>
              "{words.monapartyTokenOwnersAddress[state.language]}"の隣の"{words.set[state.language]}"をクリックしてください。<br/>
              自分が持っているモナパトークンのオーナー（発行者）のアドレスに
                <span className='relative'>
                  <img className='monaEm' src={iconMonaToMark} />
                  <span className='treeNumber'>W</span>
                </span>
              が付きます。<br/>
              "{words.clear[state.language]}"をクリックするとマークは消えます。
            </div>
          </div>
        </div>
        </div>
    </div>
  );
}

function SpecificationOfSigning() {
  return (
    <div className=''>
      <HeaderHelp />
      <div className='headerHelpPaddng' />
      <div className='textLeft fontSize1p5 marginHelp'>
        署名方式
      </div>
      <div className='textLeft marginHelp'>
        monatrustでは、その名の通りMonacoinの署名方式を採用しています。Monacoinでは、Bitcoin同様ECDSA(secp256k1)を利用しています。
        両者の差異として、メッセージに付けるプレフィクスが挙げられます。<br/>
        <br/>
        <table className='marginHelp'>
          <tr>
            <td className='textCenter'> Bitcoin </td>
            <td> "\x18Bitcoin Signed Message:\n" + compactSizeEncoding( len(message) ) </td>
          </tr>
          <tr>
            <td className='textCenter'> Monacoin </td>
            <td> "\x19Monacoin Signed Message:\n" + compactSizeEncoding( len(message) ) </td>
          </tr>
        </table>
        <br/>
        compactSizeEncodingについては、<a href='https://en.bitcoin.it/wiki/Protocol_documentation#Variable_length_integer'>ここ</a>の
        Variable length integerを参照ください。<br/>
        また、署名検証用のアドレスのプレフィクスも異なります。<br/>
        <br/>
        <table className='marginHelp displaySpNone'>
          <tr>
            <td className='textCenter'></td>
            <td className='textCenter'> Base58 </td>
            <td className='textCenter'> Base58(エンコード後) </td>
            <td className='textCenter'> Bech32 </td>
          </tr>
          <tr>
            <td className='textCenter'> Bitcoin </td>
            <td className='textCenter'> 0x00 </td>
            <td className='textCenter'> 1 </td>
            <td className='textCenter'> bc </td>
          </tr>
          <tr>
            <td className='textCenter'> Monacoin </td>
            <td className='textCenter'> 0x32 </td>
            <td className='textCenter'> M </td>
            <td className='textCenter'> mona </td>
          </tr>
        </table>
        <table className='marginHelp displayNoneSpBlock'>
          <tr>
            <td className='textCenter' rowSpan='6'> Bitcoin </td>
            <td className='textCenter'> Base58 </td>
          </tr>
          <tr>
            <td className='textCenter'> 0x00 </td>
          </tr>
          <tr>
            <td className='textCenter'> Base58(エンコード後) </td>
          </tr>
          <tr>
            <td className='textCenter'> 1 </td>
          </tr>
          <tr>
            <td className='textCenter'> Bech32 </td>
          </tr>
          <tr>
            <td className='textCenter'> bc </td>
          </tr>
          <tr>
            <td className='textCenter' rowSpan='6'> Monacoin </td>
            <td className='textCenter'> Base58 </td>
          </tr>
          <tr>
            <td className='textCenter'> 0x32 </td>
          </tr>
          <tr>
            <td className='textCenter'> Base58(エンコード後) </td>
          </tr>
          <tr>
            <td className='textCenter'> M </td>
          </tr>
          <tr>
            <td className='textCenter'> Bech32 </td>
          </tr>
          <tr>
            <td className='textCenter'> mona </td>
          </tr>
        </table>
        <br/>
        アドレスは、Base58とBech32のどちらの形式でも構いません。当然ですが、署名とアドレスの形式は揃えてください。
         また、MpurseはBech32に非対応なので、Bech32を使いたい場合は、後述の具体例を参考にするなど、他の方法で署名を作成してください。
        （くれぐれも、秘密鍵を外部に出すことのないよう注意してください。）
        <br/>
        そのほか、monatrustにおいては表に出てくる違いではありませんが、秘密鍵をWIF形式にする際のプレフィクスが異なったりします。
        （秘密鍵をWIF形式からデコードする際に必要になる値です。）<br/>
        <br/>
        <table className='marginHelp'>
          <tr>
            <td className='textCenter'> Bitcoin </td>
            <td> 0x80 </td>
          </tr>
          <tr>
            <td className='textCenter'> Monacoin </td>
            <td> 0xb0 </td>
          </tr>
        </table>
        <br/>
        monatrustにおいて、証明書に署名する際のメッセージは以下のとおりです。<br/>
        <br/>
        <table className='marginHelp'>
          <tr>
            <td className='textCenter'> 署名時 </td>
            <td> sha256( 'monatrust:1:certify,' + certificant address + ',' + community + ',' + id + ',' + time ) </td>
          </tr>
          <tr>
            <td className='textCenter'> 無効化署名時 </td>
            <td> sha256( 'monatrust:1:revoke,' + certificant address + ',' + community + ',' + id + ',' + time ) </td>
          </tr>
        </table>
        <div className='textLeft marginHelp'>
          ※ プレフィクスの中の「1」は署名バージョンを表しています。<br/>
        </div>
        <br/>
        生成された署名のエンコード方式は、<a href='https://github.com/bitcoin/bips/blob/master/bip-0137.mediawiki'>BIP-0137</a>を参照ください。<br/>
        <br/>
      </div>
      <div className='textLeft fontSize1p5 marginHelp'>
        証明書の署名・検証の具体例
      </div>
      <div className='textLeft marginHelp'>
        <div>
          ■bitcoinjs(bitcoinjs-lib, bitcoinjs-message)を使った署名
        </div>
        <pre className='code'>
        <code>
          {"const bitcoin = require('bitcoinjs-lib')"}<br/>
          {"const bitcoinMessage = require('bitcoinjs-message')"}<br/>
          {"const crypto = require('crypto')"}<br/>
          <br/>
          {"monacoinNetwork = {"}<br/>
          {"  messagePrefix: '\\x19Monacoin Signed Message:\\n',"}<br/>
          {"  bech32: 'mona',"}<br/>
          {"  bip32: {"}<br/>
          {"    public: 0x0488b21e,"}<br/>
          {"    private: 0x0488ade4,"}<br/>
          {"  },"}<br/>
          {"  pubKeyHash: 0x32,"}<br/>
          {"  scriptHash: 0x05,"}<br/>
          {"  wif: 0xb0,"}<br/>
          {"};"}<br/>
          <br/>
          {"const keyPair = bitcoin.ECPair.fromWIF('****************************************************', monacoinNetwork);"}<br/>
          {"const certificantAddress = 'MUBqe6g86L3X8cgjstAiEKFPdFdS6RDcnB';"}<br/>
          {"const community = 'twitter';"}<br/>
          {"const id = 'wakiyama'"}<br/>
          {"const time = '1605932913723'"}<br/>
          {"const message = crypto.createHash('sha256').update('monatrust:1:certify,' + certificantAddress + ',' + community + ',' + id + ',' + time).digest('hex');"}<br/>
          <br/>
          {"const signature = bitcoinMessage.sign(message, keyPair.privateKey, keyPair.compressed, monacoinNetwork.messagePrefix)"}<br/>
          {"// const signature = bitcoinMessage.sign(message, keyPair.privateKey, keyPair.compressed, monacoinNetwork.messagePrefix, { extraEntropy: crypto.randomBytes(32) }) ※非決定性署名"}<br/>
          {"// const signature = bitcoinMessage.sign(message, keyPair.privateKey, keyPair.compressed, monacoinNetwork.messagePrefix, { segwitType: 'p2wpkh' }) ※Bech32"}<br/>
          {"console.log(signature.toString('base64'));"}<br/>
        </code>
        </pre>
        <div>
          この例では、monacoinNetworkの中の値は、messagePrefixとwifしか使っていませんが、bitcoinMessage.signの中でオブジェクトの形式チェックを行っているよう
          です。また、非決定性署名を作成したい場合は、オプションのextraEntropyに乱数を設定してください。Bech32アドレスで検証できる署名を作成する場合は、
          オプションのsegwitTypeに'p2wpkh'を設定してください。<br/>
        </div>
        <br/>
        <div>
          ■bitcoinjs(bitcoinjs-message)を使った検証
        </div>
        <pre className='code'>
        <code>
          {"const bitcoinMessage = require('bitcoinjs-message')"}<br/>
          {"const messagePrefix = \"\\x19Monacoin Signed Message:\\n\";"}<br/>
          {"const crypto = require('crypto')"}<br/>
          <br/>
          {"const certificantAddress = 'MUBqe6g86L3X8cgjstAiEKFPdFdS6RDcnB';"}<br/>
          {"const community = 'twitter';"}<br/>
          {"const id = 'wakiyama'"}<br/>
          {"const time = '1605932913723'"}<br/>
          {"const message = crypto.createHash('sha256').update('monatrust:1:certify,' + certificantAddress + ',' + community + ',' + id + ',' + time).digest('hex');"}<br/>
          {"const certifierAddress = 'MJehBJHrp8W46XFNpr2QZiy9vRqMVKNpPq'"}<br/>
          {"const signature = 'HzTTum/0LL16oXdB8CqJkMVr1BIuPqMAyeEak2Tw9N3oQ3/6i8XxAkBLCLGt84I6fBxmvDRQ0rsOz5OlEebd+Lw='"}<br/>
          <br/>
          {"let isVerified;"}<br/>
          <br/>
          {"try {"}<br/>
          {"  isVerified = bitcoinMessage.verify(message, certifierAddress, signature, messagePrefix, false);"}<br/>
          {"}"}<br/>
          {"catch (e) {"}<br/>
          {"  // 検証失敗のときに、bitcoinMessage.verifyがエラーとなることがある。"}<br/>
          {"  console.log('verification catched');"}<br/>
          {"  isVerified = false;"}<br/>
          {"}"}<br/>
          <br/>
          {"console.log(isVerified);"}<br/>
        </code>
        </pre>
        <br/>
        <div>
          ■bitcoin-rubyを使った署名
        </div>
        <pre className='code'>
        <code>
          {"require 'bitcoin'"}<br/>
          {"require 'digest'"}<br/>
          <br/>
          {"Bitcoin.network = :monacoin"}<br/>
          <br/>
          {"keyPair = Bitcoin::Key.from_base58('****************************************************')"}<br/>
          {"certificantAddress = 'MUBqe6g86L3X8cgjstAiEKFPdFdS6RDcnB';"}<br/>
          {"community = 'twitter';"}<br/>
          {"id = 'wakiyama'"}<br/>
          {"time = '1605932913723'"}<br/>
          {"message = Digest::SHA256.hexdigest('monatrust:1:certify,' + certificantAddress + ',' + community + ',' + id + ',' + time)"}<br/>
          <br/>
          {"signature = keyPair.sign_message(message)"}<br/>
          {"puts signature "}<br/>
        </code>
        </pre>
          また、lib/bitcoin.rbの末尾に、bitcoinやlitecoinなどの各種固有値が定義されているので、litecoinより後ろにmonacoinの定義も追加してください。
          （一部、litecoinの定義を流用しています。）
        <br/>
        <pre className='code'>
        <code>
          {"  NETWORKS[:monacoin] = NETWORKS[:litecoin].merge({"}<br/>
          {"      magic_head: \"\\xfb\\xc0\\xb6\\xdb\","}<br/>
          {"      message_magic: \"Monacoin Signed Message:\\n\","}<br/>
          {"      address_version: \"32\","}<br/>
          {"      p2sh_version: \"05\","}<br/>
          {"      bech32_hrp: \"mona\","}<br/>
          {"      extended_privkey_version: \"0488ade4\","}<br/>
          {"      extended_pubkey_version: \"0488b21e\","}<br/>
          {"    })"}<br/>
        </code>
        </pre>
        <br/>
        <div>
          ■bitcoin-rubyを使った検証
        </div>
        <pre className='code'>
        <code>
          {"require 'bitcoin'"}<br/>
          {"require 'digest'"}<br/>
          <br/>
          {"Bitcoin.network = :monacoin"}<br/>
          <br/>
          {"certificantAddress = 'MUBqe6g86L3X8cgjstAiEKFPdFdS6RDcnB';"}<br/>
          {"community = 'twitter';"}<br/>
          {"id = 'wakiyama'"}<br/>
          {"time = '1605932913723'"}<br/>
          {"message = Digest::SHA256.hexdigest('monatrust:1:certify,' + certificantAddress + ',' + community + ',' + id + ',' + time)"}<br/>
          {"certifierAddress = 'MJehBJHrp8W46XFNpr2QZiy9vRqMVKNpPq'"}<br/>
          {"signature = 'H9bANF21KoPSneLV5wOxtEAa+/B5X7kwrcYMcdJ5YStt6AbMMOxru6ofpORIBtL3vsLannNen7/NNV521jWxB34='"}<br/>
          <br/>
          {"isVerified =  Bitcoin.verify_message(certifierAddress, signature, message)"}<br/>
          {"puts isVerified"}<br/>
        </code>
        </pre>
          署名同様、monacoinの固有値定義が必要になります。<br/>
        <br/>
        <div>
          《bitcoin-rubyでのBech32アドレス対応》<br/>
          <br/>
          bitcoin-rubyのBitcoin.sign_message, Bitcoin.verify_messageは、Bech32アドレスに対応していません。bitcoin-rubyでBech32アドレスに対応した
          署名作成・検証を行う場合、ライブラリに対して以下のようなコード修正を行ってください。<br/>
          <br/>
          □lib/bitcoin/key.rb<br/>
          <pre className='code'>
          <code>
            {"def sign_message(message, segwit_type=nil)"}<br/>
            {"  Bitcoin.sign_message(priv, pub, message, segwit_type)['signature']"}<br/>
            {"end"}<br/>
          </code>
          </pre>
          <br/>
          □lib/bitcoin.rb<br/>
          <pre className='code'>
          <code>
            {"def sign_message(private_key_hex, public_key_hex, message, segwit_type=nil)"}<br/>
            {"  hash = bitcoin_signed_message_hash(message)"}<br/>
            {"  signature = OpenSSL_EC.sign_compact(hash, private_key_hex, public_key_hex)"}<br/>
            <br/>
            {"  if segwit_type"}<br/>
            {"    version, body = signature.unpack(\"H2H*\")"}<br/>
            {"    rec_id = (version.to_i(16) + 1).modulo(4)"}<br/>
            <br/>
            {"    if segwit_type == 'p2sh(p2wpkh)'"}<br/>
            {"      corrected_version = (35 + rec_id).to_s(16)"}<br/>
            {"    elsif segwit_type == 'p2wpkh'"}<br/>
            {"      corrected_version = (39 + rec_id).to_s(16)"}<br/>
            {"    else"}<br/>
            {"      raise 'Unrecognized segwit_type: use \"p2wpkh\" or \"p2sh(p2wpkh)\"'"}<br/>
            {"    end"}<br/>
            <br/>
            {"    signature = [ corrected_version, body ].pack(\"H2H*\")"}<br/>
            {"  end"}<br/>
            <br/>
            {"  { 'address' => pubkey_to_address(public_key_hex), 'message' => message, 'signature' => [ signature ].pack(\"m0\") }"}<br/>
            {"end"}<br/>
            <br/>
            {"def verify_message(address, signature, message)"}<br/>
            {"  signature = signature.unpack(\"m0\")[0] rescue nil # decode base64"}<br/>
            {"  return false unless valid_address?(address)"}<br/>
            {"  return false unless signature"}<br/>
            {"  return false unless signature.bytesize == 65"}<br/>
            {"  hash = bitcoin_signed_message_hash(message)"}<br/>
            {"  pubkey, address_type = OpenSSL_EC.recover_compact(hash, signature, true)"}<br/>
            <br/>
            {"  if (address_type == 'bech32')"}<br/>
            {"    encode_segwit_address(0, hash160(pubkey)) == address if pubkey"}<br/>
            {"  else"}<br/>
            {"    pubkey_to_address(pubkey) == address if pubkey"}<br/>
            {"  end"}<br/>
            {"end"}<br/>
          </code>
          </pre>
          <br/>
          □lib/bitcoin/ffi/openssl.rb<br/>
          <pre className='code'>
          <code>
            {"def self.recover_compact(hash, signature, return_address_type=false)"}<br/>
            {"  return false if signature.bytesize != 65"}<br/>
            {"  msg32 = FFI::MemoryPointer.new(:uchar, 32).put_bytes(0, hash)"}<br/>
            <br/>
            {"  version = signature.unpack('C')[0]"}<br/>
            {"  return false if version < 27 || version > 42"}<br/>
            <br/>
            {"  compressed = version >= 31"}<br/>
            {"  address_type = 'base58'"}<br/>
            <br/>
            {"  if version >= 39"}<br/>
            {"    version -= 12"}<br/>
            {"    address_type = 'bech32'"}<br/>
            {"  elsif version >= 35"}<br/>
            {"    version -= 8"}<br/>
            {"  elsif version >= 31"}<br/>
            {"    version -= 4"}<br/>
            {"  end"}<br/>
            <br/>
            {"  if return_address_type"}<br/>
            {"    return [ recover_public_key_from_signature(msg32.read_string(32), signature, version - 27, compressed), address_type ]"}<br/>
            {"  else"}<br/>
            {"    return recover_public_key_from_signature(msg32.read_string(32), signature, version - 27, compressed)"}<br/>
            {"  end"}<br/>
            {"end"}<br/>
          </code>
          </pre>
          <br/>
          上記コード修正を行った上で、下記のとおり署名・検証を行ってください。<br/>
          <br/>
          ■bitcoin-ruby(改)を使った署名
          <pre className='code'>
          <code>
            {"require 'bitcoin'"}<br/>
            {"require 'digest'"}<br/>
            <br/>
            {"Bitcoin.network = :monacoin"}<br/>
            <br/>
            {"keyPair = Bitcoin::Key.from_base58('****************************************************')"}<br/>
            {"certificantAddress = 'MUBqe6g86L3X8cgjstAiEKFPdFdS6RDcnB';"}<br/>
            {"community = 'twitter';"}<br/>
            {"id = 'wakiyama'"}<br/>
            {"time = '1605932913723'"}<br/>
            {"message = Digest::SHA256.hexdigest('monatrust:1:certify,' + certificantAddress + ',' + community + ',' + id + ',' + time)"}<br/>
            <br/>
            {"signature = keyPair.sign_message(message, 'p2wpkh')"}<br/>
            {"puts signature "}<br/>
          </code>
          </pre>
          Bitcoin::Key.sign_messageの第二引数に'p2wpkh'を渡すと、Bech32アドレスで検証できる署名が作成されます。何も渡さなければ、Base58対応署名になります。<br/>
          <br/>
          ■bitcoin-ruby(改)を使った検証
          <pre className='code'>
          <code>
            {"require 'bitcoin'"}<br/>
            {"require 'digest'"}<br/>
            <br/>
            {"Bitcoin.network = :monacoin"}<br/>
            <br/>
            {"certificantAddress = 'MUBqe6g86L3X8cgjstAiEKFPdFdS6RDcnB';"}<br/>
            {"community = 'twitter';"}<br/>
            {"id = 'wakiyama'"}<br/>
            {"time = '1605932913723'"}<br/>
            {"message = Digest::SHA256.hexdigest('monatrust:1:certify,' + certificantAddress + ',' + community + ',' + id + ',' + time)"}<br/>
            {"certifierAddress = 'mona1qwhk05pqak6wlwehkfal5calqf2jtt4p0jve3j4'"}<br/>
            {"signature = 'KGt8wbRprk3tx0s/eXhCqbrxectD3D4WnBwWdAfDoQVfK4FBavJa9z/PrSRCh6p4GaUIRSmH3Ny7e/AKfHo03HM='"}<br/>
            <br/>
            {"isVerified =  Bitcoin.verify_message(certifierAddress, signature, message)"}<br/>
            {"puts isVerified"}<br/>
          </code>
          </pre>
          特にコードの修正は不要です。（ライブラリが渡された署名でアドレス形式を判定します。）<br/>
        </div>
      </div>
    </div>
  );
}

function SpecificationOfUrlParameters() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div className=''>
      <HeaderHelp />
      <div className='headerHelpPaddng' />
      <div className='textLeft fontSize1p5 marginHelp'>
        URLパラメータ
      </div>
      <div className='textLeft marginHelp'>
        monatrustでは、以下のURLパラメータを受け付けています。<br/>
        これにより、モナコインアドレスをユーザIDとするようなWebサイトから、ユーザ特定や本人性確認、与信のためにmonatrustに連携することも簡単にできます。
        URLパラメータを設定する際は、署名の中の"+"など、パーセントエンコーディングが必要な文字に注意してください。<br/>
        <br/>
        <table className='marginHelp'>
          <tr>
            <td className='textCenter'>
              screenMode
            </td>
            <td className='textLeft'>
              表示する画面です。メニューの項目に対応すると考えてもらうとだいたい合っています。<br/>
              ＜設定値＞<br/>
              searchCertificateCore(デフォルト), insertCertificate, signMessage, verifySignature, setting, help
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              searchPattern
            </td>
            <td className='textLeft'>
              {words.searchMode[state.language]}画面の検索パターンです。<br/>
              ＜設定値＞<br/>
              scan(全件検索), no, id(デフォルト), community, community_id, certificantAddress, certifierAddress
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              no
            </td>
            <td className='textLeft'>
              {words.searchMode[state.language]}画面の検索条件として使われる"{words.number[state.language]}"です。<br/>
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              community
            </td>
            <td className='textLeft'>
              {words.searchMode[state.language]}画面の検索条件として使われる"{words.community[state.language]}"です。<br/>
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              id
            </td>
            <td className='textLeft'>
              {words.searchMode[state.language]}画面の検索条件として使われる"{words.id[state.language]}"です。<br/>
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              certificantAddress
            </td>
            <td className='textLeft'>
              {words.searchMode[state.language]}画面の検索条件として使われる"{words.certificantAddress[state.language]}(被証明者のアドレス)"です。<br/>
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              certifierAddress
            </td>
            <td className='textLeft'>
              {words.searchMode[state.language]}画面の検索条件として使われる"{words.certifierAddress[state.language]}(証明者のアドレス)"です。<br/>
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              displayRevoked
            </td>
            <td className='textLeft'>
              {words.searchMode[state.language]}画面の"{words.revoked[state.language]}"フラグです。無効化証明書、無効化署名の表示を制御します。<br/>
              ＜設定値＞<br/>
              true, false(デフォルト)
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              autoSearch
            </td>
            <td className='textLeft'>
              "on"を設定した場合、{words.searchMode[state.language]}画面にて、与えられた検索条件で"{words.search[state.language]}"ボタンが押された状態となります。<br/>
              ＜設定値＞<br/>
              on
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              insertCertificantAddress
            </td>
            <td className='textLeft'>
              {words.insertMode[state.language]}画面の"{words.address[state.language]}"です。
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              insertCommunity
            </td>
            <td className='textLeft'>
              {words.insertMode[state.language]}画面の"{words.community[state.language]}"です。
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              insertId
            </td>
            <td className='textLeft'>
              {words.insertMode[state.language]}画面の"{words.id[state.language]}"です。
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              insertTime
            </td>
            <td className='textLeft'>
              {words.insertMode[state.language]}画面の"{words.time[state.language]}"です。
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              insertSignature
            </td>
            <td className='textLeft'>
              {words.insertMode[state.language]}画面の"{words.signature[state.language]}"です。このパラメータを設定するということは、monatrust外で署名するということです。
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              messageToSign
            </td>
            <td className='textLeft'>
              {words.signMessageMode[state.language]}画面の"{words.message[state.language]}"です。
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              messageToVerify
            </td>
            <td className='textLeft'>
              {words.verifySignatureMode[state.language]}画面の"{words.message[state.language]}"です。
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              signatureToVerify
            </td>
            <td className='textLeft'>
              {words.verifySignatureMode[state.language]}画面の"{words.signature[state.language]}"です。
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              addressForVerify
            </td>
            <td className='textLeft'>
              {words.verifySignatureMode[state.language]}画面の"{words.address[state.language]}"です。
            </td>
          </tr>
          <tr>
            <td className='textCenter'>
              addressToMark
            </td>
            <td className='textLeft'>
              {words.settingMode[state.language]}画面の"{words.addressToMark[state.language]}"です。
            </td>
          </tr>
        </table>
        <br/>
        ■パラメータ設定例<br/>
        <br/>
          アドレスがMJehBJHrp8W46XFNpr2QZiy9vRqMVKNpPqである証明書を検索した画面を開く場合。<br/>
        <br/>
        <div className='wordBreakBreakAll simpleBox'>
          https://www.monatrust.com/?searchPattern=certificantAddress&certificantAddress=MJehBJHrp8W46XFNpr2QZiy9vRqMVKNpPq&autoSearch=on
        </div>
      </div>
    </div>
  );
}

function SpecificationOfApi() {
  return (
    <div className=''>
      <HeaderHelp />
      <div className='headerHelpPaddng' />
      <div className='textLeft fontSize1p5 marginHelp'>
        API
      </div>
      <div className='textLeft marginHelp'>
        <div>
          ■共通事項
          <div className='textLeft marginHelp'>
            <ul className='disc'>
              <li>
                エンドポイント<br/>
                https://api.monatrust.com/<span className='italic'>resource_name</span><br/>
                <br/>
              </li>
              <li>
                メソッド<br/>
                POST<br/>
                <br/>
              </li>
              <li>
                ヘッダ<br/>
                Content-Type: application/json<br/>
                <br/>
              </li>
              <li>
                パラメータ<br/>
                以下のようなJSON形式でパラメータをセットしてください。<br/>
                <br/>
                {'{ "body" : { '}<span className='italic'>parameter_name1</span> : <span className='italic'>value1</span>, <span className='italic'>parameter_name2</span> : <span className='italic'>value2</span>{', ... }}'}<br/>
                <br/>
              </li>
              <li>
                リクエスト例<br/>
                <div className='wordBreakBreakAll simpleBox'>
                  {'curl -X POST -H "Content-Type: application/json" -d \'{ "body": { "community": "twitter", "id": "jung2neko" }}\' https://api.monatrust.com/select_certificate_core'}<br/>
                </div>
                <br/>
              </li>
              <li>
                レスポンス<br/>
                以下のようなJSON形式でレスポンスが返却されます。<br/>
                <span className='italic'>JSON_string</span>は文字列なので、JSONとして利用するにはこの部分を再度parseしてください。<br/>
                <br/>
                {'{ "applicationStatus": "200", "applicationMessage": "success", "body": '}<span className='italic'>JSON_string</span>{' }'}<br/>
                <br/>
              </li>
              <li>
                時刻<br/>
                時刻の表現はエポックミリ秒です。
                末尾に"client"と付くものは自己申告の時刻、付かないものはサーバ側の時刻です。
                <br/>
                <br/>
              </li>
            </ul>
          </div>
        </div>
          以下、各リソースについての説明です。<br/>
          <br/>
        <div>
          ■select_certificate_core
          <div className='textLeft marginHelp'>
            証明書の被認証者に関する情報を取得します。
            <ul className='disc'>
              <li>
                リクエストパラメータ<br/>
                <ul className='circle'>
                  <li>
                    番号で検索<br/>
                    no(<span className='italic'>number</span>)<br/>
                    ※検索結果は一意に定まります。<br/>
                    <br/>
                  </li>
                  <li>
                    IDで検索<br/>
                    id<br/>
                    <br/>
                  </li>
                  <li>
                    コミュニティで検索<br/>
                    community<br/>
                    <br/>
                  </li>
                  <li>
                    IDとコミュニティで検索<br/>
                    id, community<br/>
                    <br/>
                  </li>
                  <li>
                    被認証者アドレスで検索<br/>
                    certificantAddress<br/>
                    <br/>
                  </li>
                  <li>
                    認証者アドレスで検索<br/>
                    certifierAddress (, excludeRevoked)<br/>
                    ※excludeRevokedにtrueをセットした場合、指定したcertifierAddressで無効化されている証明書は検索対象に含まれません。<br/>
                    <br/>
                  </li>
                  <li>
                    全件検索<br/>
                    なし（bodyを空オブジェクトにしてください。）<br/>
                    <br/>
                  </li>
                </ul>
                <br/>
              </li>
              <li>
                レスポンス<br/>
                以下のプロパティを持つオブジェクトの配列を返却します。(一意に定まる場合も配列として返却します。)検索対象が存在しないときは空配列を返却します。<br/>
                <br/>
                no(<span className='italic'>number</span>), certificantAddress, id, community, creationTimeClient(<span className='italic'>number</span>), creationTime(<span className='italic'>number</span>) (, revocationTimeClient(<span className='italic'>number</span>), revocationTime(<span className='italic'>number</span>))<br/>
                <br/>
              </li>
            </ul>
          </div>
        </div>
        <div>
          ■select_certifier
          <div className='textLeft marginHelp'>
            証明書の認証者のアドレスと署名に関する情報を取得します。
            <ul className='disc'>
              <li>
                リクエストパラメータ<br/>
                <ul className='circle'>
                  <li>
                    番号と認証者アドレスで検索<br/>
                    no(<span className='italic'>number</span>), certifierAddress<br/>
                    ※検索結果は一意に定まります。<br/>
                    <br/>
                  </li>
                  <li>
                    番号で検索<br/>
                    no(<span className='italic'>number</span>)<br/>
                    <br/>
                  </li>
                  <li>
                    認証者アドレスで検索<br/>
                    certifierAddress<br/>
                    <br/>
                  </li>
                  <li>
                    全件検索<br/>
                    なし（bodyを空オブジェクトにしてください。）<br/>
                    <br/>
                  </li>
                </ul>
              </li>
              <li>
                レスポンス<br/>
                以下のプロパティを持つオブジェクトの配列を返却します。(一意に定まる場合も配列として返却します。)検索対象が存在しないときは空配列を返却します。<br/>
                <br/>
                no(<span className='italic'>number</span>), certifierAddress, signatureOfCore, creationTimeClient(<span className='italic'>number</span>), creationTime(<span className='italic'>number</span>), historyNumber(<span className='italic'>number</span>) (, signatureOfRevocation, revocationTimeClient(<span className='italic'>number</span>), revocationTime(<span className='italic'>number</span>), onchainRevokeTXID)<br/>
                <br/>
              </li>
            </ul>
          </div>
        </div>
        <div>
          ■select_certifier_history
          <div className='textLeft marginHelp'>
            署名の履歴を取得します。
            <ul className='disc'>
              <li>
                リクエストパラメータ<br/>
                <ul className='circle'>
                  <li>
                    番号と認証者アドレスと履歴番号で検索<br/>
                    noAndCertifierAddress (== concatenate(no, '_', certifierAddress)), historyNumber(<span className='italic'>number</span>)<br/>
                    ※検索結果は一意に定まります。<br/>
                    <br/>
                  </li>
                  <li>
                    番号と認証者アドレスとで検索<br/>
                    noAndCertifierAddress (== concatenate(no, '_', certifierAddress))<br/>
                    <br/>
                  </li>
                  <li>
                    全件検索<br/>
                    なし（bodyを空オブジェクトにしてください。）<br/>
                    <br/>
                  </li>
                </ul>
              </li>
              <li>
                レスポンス<br/>
                以下のプロパティを持つオブジェクトの配列を返却します。(一意に定まる場合も配列として返却します。)検索対象が存在しないときは空配列を返却します。最新の署名情報は結果に含まれません。<br/>
                <br/>
                noAndCertifierAddress, historyNumber(<span className='italic'>number</span>), signatureOfCore, creationTimeClient(<span className='italic'>number</span>), creationTime(<span className='italic'>number</span>), signatureOfRevocation, revocationTimeClient(<span className='italic'>number</span>), revocationTime(<span className='italic'>number</span>) (, onchainRevokeTXID)<br/>
                <br/>
              </li>
            </ul>
          </div>
        </div>
        <div>
          ■insert_certificate
          <div className='textLeft marginHelp'>
            新規に証明書を作成します。
            <ul className='disc'>
              <li>
                リクエストパラメータ<br/>
                certificantAddress, community, id, creationTimeClient(<span className='italic'>number</span>), signature<br/>
                ※番号(no)は自動付与されます。<br/>
                <br/>
              </li>
              <li>
                レスポンス<br/>
                空オブジェクトを返却します。<br/>
                <br/>
              </li>
            </ul>
          </div>
        </div>
        <div>
          ■insert_certifier
          <div className='textLeft marginHelp'>
            既存の証明書に署名します。
            <ul className='disc'>
              <li>
                リクエストパラメータ<br/>
                no(<span className='italic'>number</span>), certifierAddress, creationTimeClient(<span className='italic'>number</span>), signature<br/>
                ※署名対象を一意に指定する必要があるので、事前に"no"を特定しておいてください。<br/>
                <br/>
              </li>
              <li>
                レスポンス<br/>
                空オブジェクトを返却します。<br/>
                <br/>
              </li>
            </ul>
          </div>
        </div>
        <div>
          ■revoke
          <div className='textLeft marginHelp'>
            署名を無効化します。
            <ul className='disc'>
              <li>
                リクエストパラメータ<br/>
                no(<span className='italic'>number</span>), certifierAddress, revocationTimeClient(<span className='italic'>number</span>), signatureOfRevocation<br/>
                ※無効化署名対象を一意に指定する必要があるので、事前に"no"を特定しておいてください。<br/>
                <br/>
              </li>
              <li>
                レスポンス<br/>
                空オブジェクトを返却します。<br/>
                <br/>
              </li>
            </ul>
          </div>
        </div>
        <div>
          ■set_txid
          <div className='textLeft marginHelp'>
            onchainRevokeのTXIDをDB上に登録します。
            <ul className='disc'>
              <li>
                リクエストパラメータ<br/>
                no(<span className='italic'>number</span>), certifierAddress, onchainRevokeTXID<br/>
                ※TXIDをセットする対象(署名)を一意に指定する必要があるので、事前に"no"(と"certifierAddress")を特定しておいてください。<br/>
                <br/>
              </li>
              <li>
                レスポンス<br/>
                空オブジェクトを返却します。<br/>
                <br/>
              </li>
            </ul>
          </div>
        </div>
        <div>
          ■verify
          <div className='textLeft marginHelp'>
            署名検証を行います。
            <ul className='disc'>
              <li>
                リクエストパラメータ<br/>
                message, address, signature<br/>
                <br/>
              </li>
              <li>
                レスポンス<br/>
                <ul className='circle'>
                  <li>
                    正しい署名の場合<br/>
                    {'{ "applicationStatus": "20A", "applicationMessage": "verified", "body": true }'}<br/>
                    <br/>
                  </li>
                  <li>
                    正しくない署名の場合<br/>
                    {'{ "applicationStatus": "20B", "applicationMessage": "not verified", "body": false }'}<br/>
                    <br/>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

// 保有トークンチェック＆初期設定
function checkTokensAndSet(state, dispatch) {
  window.mpurse.getAddress()
    .then( async (result) => {
      let myAddress = result;
      devLog('myAddress', myAddress);

      let request = {};
      let response;

      request.url = 'https://wallet.monaparty.me/_api';
      request.body = `{"jsonrpc":"2.0","id":0,"method":"get_normalized_balances","params":{"addresses": ["${myAddress}"]}}`
      const messages = {
         "fulfilled": 'success'
      };

      response = await syncHttpRequest(request);
      devLog('response', JSON.stringify(response));

      if (response.status === 'rejected') {
        return 'rejected'
      }

      // 各種設定
      response.body.result.forEach(token => {
        if (token.asset_longname === 'TDKN.ALEX') {
          dispatch({ type: 'setState', key: 'language', value: 'japanese' });
        }
      });
    });
}

// HTTPリクエスト（同期バージョン）
async function syncHttpRequest(request) {
  const method = 'POST';
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  return await fetch(request.url, { method: method, header: headers, body: request.body })
  .then( response => response.json() )
  .then( json => {
    devLog('syncHttpRequest fulfilled', JSON.stringify(json));
    return { status: 'fulfilled', body: json };
  })
  .catch( error => {
    devLog('syncHttpRequest rejected', JSON.stringify(error));
    return { status: 'rejected', body: error };
  });
}

// devLog
function devLog(...words) {
  if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    console.log(words.join(' '));
  }
  return 1;
}

export default Help;

